import React from 'react';
import { Box, Typography, Grid, Container, CardContent, CardMedia } from '@mui/material';

// Import image paths (replace paths as necessary)
import firstImage from '../assets/service1.jpg';
import img1 from '../assets/service2.jpeg';
import img2 from '../assets/service3.jpeg';
import img3 from '../assets/service4.jpeg';
import img4 from '../assets/service5.jpeg';
import img5 from '../assets/service6.jpeg';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Services = () => {
  const servicesData = [
    {
      title: 'Content Typing & Formatting',
      description:
        'Transcribe handwritten or printed documents into digital text for website use. Format content according to your website’s design and layout specifications, including headings, paragraphs, lists, and hyperlinks.',
      image: img1,
      backgroundColor: '#112d57',
      color: '#fff',
    },
    {
      title: 'SEO-Optimized Typing',
      description:
        'Incorporate relevant keywords into the typed content to improve search engine visibility. Ensure proper use of meta tags, alt text for images, and other SEO elements.',
      image: img2,
      backgroundColor: '#f0f4ff',
    },
    {
      title: 'Data Entry and Form Filling',
      description:
        'Efficiently type and organize data for website forms, databases, or online directories. Ensure accuracy and consistency across all entries.',
      image: img3,
      backgroundColor: '#f0f4ff',
    },
    {
      title: 'Multilingual Typing',
      description:
        'Provide typing services in multiple languages, ensuring correct grammar, punctuation, and localization for your target audience.',
      image: img4,
      backgroundColor: '#f0f4ff',
    },
    {
      title: 'Content Migration',
      description:
        'Assist in migrating content from old websites or documents to a new site, ensuring that everything is typed and formatted correctly.',
      image: img5,
      backgroundColor: '#f0f4ff',
    },
  ];

  return (
    <>
      <Navbar />
      <Container sx={{ marginBottom: '60px' }}>
        {/* Top horizontal image */}
        <Box sx={{ textAlign: 'center', mt: 2, mb: 4 }}>
          <CardMedia
            component="img"
            height="520" 
            image={firstImage}
            alt="Services Banner"
            sx={{
              width: '100%',
              height: '500px',
              marginBottom: '-5px',
            }}
          />
          <Typography variant="h4" fontWeight="bold" sx={{ marginTop: '10px' }}>
            Services
          </Typography>
        </Box>

        {/* Services list */}
        <Grid container spacing={2}>
          {servicesData.map((item, index) => (
            <Grid
              item
              xs={12}
              key={index}
              container
              direction={index % 2 === 0 ? 'row-reverse' : 'row'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: item.backgroundColor || '#f0f4ff',
                color: item.color || 'inherit',
              }}
            >
              {/* Image */}
              <Grid item xs={12} md={6}>
                <CardMedia
                  component="img"
                  sx={{
                    width: '80%',
                    height: '80%',
                    objectFit: 'cover',
                  }}
                  image={item.image}
                  alt={item.title}
                />
              </Grid>

              {/* Text */}
              <Grid item xs={12} md={5}>
                <CardContent sx={{ padding: '29px' }}>
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2">
                    {item.description}
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
      
     <Footer /> 
    </>
  );
};

export default Services;
