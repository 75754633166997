import React, { useState, useEffect } from 'react';
import './SignUp.css';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userType, setUserType] = useState("");
    const [secretKey, setSecretKey] = useState("");
    const [selectedSet, setSelectedSet] = useState("");
    const [submissionDate, setSubmissionDate] = useState(new Date().toISOString().slice(0, 16)); // Initialize submission date
const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();

        if (userType === "Admin" && secretKey !== "Pagetyping") {
            alert("Invalid Admin");
        } else {
            fetch("http://localhost:5001/register", {
                method: "POST",
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({
                    fname,
                    lname,
                    email,
                    password,
                    userType,
                    selectedSet,
                    submissionDate
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data, "userRegister");

                    if (data.status === "ok") {
                        alert("Register Successfully...");
                        window.localStorage.setItem("token", data.data);
                        window.localStorage.setItem("selectedSet", selectedSet); 
                        navigate('/signinpage', { state: { selectedSet } });

                       
                    } else {
                        alert("Already registered with this email");
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    return (
        <div className="signup">
            <form onSubmit={handleSubmit}>
                <h3>Sign Up</h3>

                <div>
                    Register As
                    <input
                        type="radio"
                        name="userType"
                        value="User"
                        onChange={(e) => setUserType(e.target.value)}
                    />
                    User
                    <input
                        type="radio"
                        name="userType"
                        value="Admin"
                        onChange={(e) => setUserType(e.target.value)}
                    />
                    Admin
                </div>

                {userType === "Admin" ? (
                    <div className="mb-3">
                        <label>Secret Key</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Secret Key"
                            value={secretKey}
                            onChange={(e) => setSecretKey(e.target.value)}
                        />
                    </div>
                ) : null}

                <div className="mb-3">
                    <label>First name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                    />
                </div>

                <div className="mb-3">
                    <label>Last name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                    />
                </div>

                <div className="mb-3">
                    <label>Email address</label>
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="mb-3">
                    <label>Password</label>
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <div className="mb-3">
                    <label>Choose Typing Set</label>
                    <select value={selectedSet} onChange={(e) => setSelectedSet(e.target.value)} required>
                        <option value="">Select a Set</option>
                        <option value="Set A">Set A</option>
                        <option value="Set B">Set B</option>
                        <option value="Set C">Set C</option>
                        {/* Add more sets as needed */}
                    </select>
                </div>

                <div className="mb-3">
                    <label>Submission Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        value={submissionDate}
                        onChange={(e) => setSubmissionDate(e.target.value)}
                    />
                </div>

                <div className="d-grid">
                    <button type="submit" className="btn btn-primary">
                        Sign Up
                    </button>
                </div>

                <p className="forgot-password text-right mt-3">
                    Already registered <a href="/signinpage">Sign In</a>
                </p>
            </form>
        </div>
    );
};

export default SignUp;
