import React from "react";
import { Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import About from './pages/About';
import Dashboard from './components/SignUp';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Login from './pages/Login';
import SignInpage from "./pages/SignInpage";
import UserInformation from "./pages/UserInformation";
import MyPage from "./components/MyPage";
import SuccessPage from "./components/SuccessPage";
import CheckResult from "./components/CheckResult";
import Result from "./components/Result";
import TypingPage from "./components/TypingPage";
import ParagraphsPage from "./components/ParagraphsPage";
import Info from "./pages/Info";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} /> 
        <Route path="/login" element={<Login />} />     
        <Route path="/signinpage" element={<SignInpage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/userinformation" element={<UserInformation />} />
        <Route path="/mypage" element={<MyPage />} />
       
        <Route path="/successpage" element={<SuccessPage />} />
        <Route path="/checkresult" element={<CheckResult />} />
        <Route path="/result" element={<Result />} />
        <Route path="/TypingPage" element={<TypingPage />} />
        <Route path="/paragraph/:pageIndex" element={<ParagraphsPage />} />
        <Route path="/info/:id" element={<Info />} />
      </Routes>
    </>
  );
};

export default App;
