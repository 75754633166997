
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './MyPage.css';

// function MyPage() {
//   const [userName, setUserName] = useState('Rahul');
//   const [selectedPage, setSelectedPage] = useState('');
//   const navigate = useNavigate();
 
//   const handleLogout = () => {
//     window.localStorage.clear();
//     window.location.href = '/signinpage';
//   };

//   const handleBack = () => {
//     window.location.href = '/userinformation';
//   };

//   const handlePageChange = (event) => {
//     const selectedValue = event.target.value;
//     setSelectedPage(selectedValue);
//     if (selectedValue) {
//       const pageIndex = parseInt(selectedValue.split(' ')[1], 10);
//       navigate(`/paragraph/${pageIndex}`);
//     }
//   };

//   const pageOptions = Array.from({ length: 500 }, (_, i) => `Page ${i + 1}`);

//   return (
//     <div>
//       {selectedPage === '' ? (
//         <div className="navbar" id='mainnavpage'>
//           <span className="navbar-brand">Page Typing</span>
//           {userName && (
//             <div className="navbar-user">
//               <button onClick={handleLogout} className="navbar-button">LOGOUT</button>
//               <span className="border-1 bg-success p-4 w-100 text-white me-4">{userName}</span>
//             </div>
//           )}
//         </div>
//       ) : (
//         <div className="navbar" id='othernavpage'>
//           <span className="navbar-brand">Other Navbar</span>
//         </div>
//       )}
//       <div>
//         <button onClick={handleBack} className="Backbtn">
//           <svg xmlns="http://www.w3.org/2000/svg" className="arrow" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
//             <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
//           </svg>
//           Back
//         </button>
//         <hr className="w-75 mx-auto text-center"></hr>
//         <h1 className="animated-form">Select Page</h1>
//         <select value={selectedPage} onChange={handlePageChange} className="page-dropdown">
//           <option value="">Select a page</option>
//           {pageOptions.map((page, index) => (
//             <option key={index} value={page}>{page}</option>
//           ))}
//         </select>
//       </div>
//     </div>
//   );
// }

// export default MyPage;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './MyPage.css';

const MyPage = () => {
  const [userName, setUserName] = useState('Rahul');
  const [selectedPage, setSelectedPage] = useState('');
  const [pageCompleted, setPageCompleted] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedSet } = location.state || {}; // Get selectedSet from location state

  useEffect(() => {
    const savedCompletionStatus = JSON.parse(localStorage.getItem('pageCompletionStatus')) || [];
    setPageCompleted(savedCompletionStatus);
  }, []);

  const handleLogout = () => {
    window.localStorage.clear();
    window.location.href = '/signinpage';
  };

  const handleBack = () => {
    window.location.href = '/userinformation';
  };

  const handlePageChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedPage(selectedValue);

    if (selectedValue) {
      const pageIndex = parseInt(selectedValue.split(' ')[1], 10);
      navigate(`/TypingPage`, { state: { selectedSet, pageIndex } });
    }
  };

  const totalPages = 500;
  const pageOptions = Array.from({ length: totalPages }, (_, i) => `Page ${i + 1}`);

  return (
    <div>
      <div className="navbar" id='mainnavpage'>
        <span className="navbar-brand">Page Typing</span>
        {userName && (
          <div className="navbar-user">
            <button onClick={handleLogout} className="navbar-button">LOGOUT</button>
            <span className="border-1 bg-success p-4 w-100 text-white me-4">{userName}</span>
          </div>
        )}
      </div>
      <div>
        <button onClick={handleBack} className="Backbtn">
          <svg xmlns="http://www.w3.org/2000/svg" className="arrow" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
          </svg>
          Back
        </button> 
        
        <div className="mt-5">
          <h1 className="animated-form">Select Page</h1>
          <select value={selectedPage} onChange={handlePageChange} className="page-dropdown">
            <option value="">Select a page</option>
            {pageOptions.map((page, index) => (
              <option key={index + 1} value={page} >
                {page}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default MyPage;
