
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PagesStyle.css';

const CheckResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { paragraph, textFields, combinedAccuracy } = location.state || {};

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  // Function to remove the ® symbol and any extra spaces
  const cleanText = (text) => text.replace(/®/g, '');

  // Function to get the difference between the cleaned original and typed text
  const getDifference = (original, typed) => {
    const cleanOriginal = cleanText(original);
    const cleanTyped = cleanText(typed);
    const diffElements = [];

    const maxLength = Math.max(cleanOriginal.length, cleanTyped.length);

    for (let i = 0; i < maxLength; i++) {
      if (i < cleanTyped.length) {
        if (cleanOriginal[i] !== cleanTyped[i]) {
          diffElements.push(<span key={i} className="fs-3 text-danger">{cleanTyped[i] === ' ' ? '_' : cleanTyped[i]}</span>);
        } else {
          diffElements.push(<span key={i}>{cleanTyped[i] === ' ' ? '_' : cleanTyped[i]}</span>);
        }
      } else {
        diffElements.push(<span key={i} className=" fs-3 text-danger">{cleanTyped[i] === ' ' ? '_' : cleanTyped[i]}</span>);
      }
    }

    return diffElements;
  };

  const referenceText = paragraph ? paragraph.split('®') : [];

  return (
    <div>
      <div className="container mt-4">
        <div className="text-center">
          <div className="row mb-3">
            <div className="col-md-4 border p-2">
              <strong>Original Text</strong>
            </div>
            <div className="col-md-4 border p-2">
              <strong>Typed Text</strong>
            </div>
            <div className="col-md-4 border p-2">
              <strong>Difference</strong>
            </div>
          </div>
          {textFields && textFields.map((text, index) => (
            <div key={index} className="row mb-3">
              <div className="col-md-4 border p-2 text-success fs-4">
                {referenceText[index]}
              </div>
              <div className="col-md-4 border p-2 text-success fs-4">
                {text}
              </div>
              <div className="col-md-4 border p-2 text-success fs-4">
                {getDifference(referenceText[index], text)}
              </div>
            </div>
          ))}
          <p>Combined Accuracy: {combinedAccuracy}%</p>
          <button onClick={handleGoBack} className="btn btn-primary">
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckResult;
