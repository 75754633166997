// import React, { Component } from 'react';
// import "../Components/UserInformation.css";
// import Dashboard from './Dashboard';

// export class UserInformation extends Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             userData: null, // Initialize userData as null instead of an empty string
//             admin: false,
//             registrationDate: null,
//             remainingDays: 0,
//             submissionDate: null,
//         };
//     }

//     componentDidMount() {
//         fetch("http://localhost:5001/userData", {
//             method: "POST",
//             crossDomain: true,
//             headers: {
//                 "Content-Type": "application/json",
//                 "Accept": "application/json",
//                 "Access-Control-Allow-Origin": "*",
//             },
//             body: JSON.stringify({
//                 token: window.localStorage.getItem("token"),
//             }),
//         })
//             .then((res) => res.json())
//             .then((data) => {
//                 console.log(data, "userData");
//                 if (data.data.userType === "Admin") {
//                     this.setState({ admin: true });
//                 }
//                 const registrationDate =data.data.registrationDate; 
//                 const remainingDays = this.calculateRemainingDays(data.data.registrationDate);
//                 const submissionDate = this.calculateSubmissionDate(registrationDate);

//                 this.setState({ userData: data.data, remainingDays, registrationDate,submissionDate });
//             });
//     }

//     logout = () => {
//         window.localStorage.clear();
//         window.location.href = "/SucessPage";
//     };

//     calculateRemainingDays = (registrationDate) => {
//         const currentDate = new Date();
//         const regDate = new Date(registrationDate);
//         const diffTime = Math.abs(currentDate - regDate);
//         const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//         return 7 - diffDays > 0 ? 7 - diffDays : 0;
//     };

//     calculateSubmissionDate = (registrationDate) => {
//         const regDate = new Date(registrationDate);
//         regDate.setDate(regDate.getDate() + 7);
//         return regDate.toISOString().split('T')[0]; // Format date to YYYY-MM-DD
//     };

    

//     render() {
//         const { admin, userData, remainingDays, registrationDate,submissionDate } = this.state;

//         return (
//             <div>
//                 {admin ? (
//                     // <h1>Welcome Admin</h1>
//                     <Dashboard></Dashboard>
//                 ) : (
//                     <div className='containerr'>
                       
//                         <div className="dashboard">
//                             <h1>Welcome to Pagetyping </h1>
                            
//                             <table className="dashboard-table">
//                                 <tbody>
//                                     <tr>
//                                         <th>Name</th>
//                                         <td>{userData?.fname} {userData?.lname}</td>
//                                     </tr>
//                                     <tr>
//                                         <th>Email Id</th>
//                                         <td>{userData?.email}</td>
//                                     </tr>
//                                     <tr>
//                                         <th>Starting Date of Assignment</th>
//                                         <td>{registrationDate}</td>
//                                     </tr>
//                                     <tr>
//                                         <th>Last Date of Submission</th>
//                                         <td>{submissionDate}</td>
//                                     </tr>
//                                     <tr>
//                                         <th>Total Number of Pages</th>
//                                         <td></td>
//                                     </tr>
//                                     <tr>
//                                         <th>Total Completed Pages</th>
//                                         <td></td>
//                                     </tr>
//                                     <tr>
//                                         <th>Total Number of Days</th>
//                                         <td>7</td>
//                                     </tr>
//                                     <tr>
//                                         <th>Total Number of Days Left </th>
//                                         <td>{remainingDays}</td>
//                                     </tr>
//                                 </tbody>
//                             </table>
//                             <p className="note">Note: You can submit your page only once you completed page.</p>
//                             {/* <button className="expired-button ms-0">Account Expired</button> */}
//                             <button className="expired-button ms-3"><a href="/mypage">Start Typing</a></button>
//                             <p className="result-info">Result will generate after completion of 50 Pages</p>
//                         </div>

//                         <button className='btn btn-primary' onClick={this.logout}>Log Out</button>
//                     </div>
//                 )}
//             </div>
//         );
//     }
// }

// export default UserInformation;

import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import "../components/UserInformation.css";
import Dashboard from '../pages/Dashboard';

class UserInformation extends Component {
    state = {
        userData: null,
        admin: false,
        registrationDate: null,
        remainingDays: 0,
        submissionDate: null,
        totalDays: 0,
        totalPages: 0,
        completedPages: 0,
        selectedSet: '',
        loading: true,
        error: null,
        navigateToTyping: false,
    };

    async componentDidMount() {
        try {
            const response = await fetch("http://localhost:5001/userData", {
                method: "POST",
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({
                    token: window.localStorage.getItem("token"),
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.data) {
                const { userType, registrationDate, submissionDate, totalPages, completedPages, selectedSet } = data.data;

                if (userType === "Admin") {
                    this.setState({ admin: true });
                }

                const regDate = new Date(registrationDate);
                const subDate = new Date(submissionDate);
                const totalDays = this.calculateTotalDays(regDate, subDate);
                const remainingDays = this.calculateRemainingDays(subDate);

                this.setState({
                    userData: data.data,
                    registrationDate: this.formatDate(regDate),
                    submissionDate: this.formatDate(subDate),
                    totalDays,
                    remainingDays,
                    totalPages: totalPages || 500,
                    completedPages: completedPages || 0,
                    selectedSet,  // Update the state with the selected set
                    loading: false,
                });
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            this.setState({ error: 'Failed to load user data', loading: false });
        }
    }

    logout = () => {
        // Clear all user-related data from localStorage, but keep the completion status
        const pageCompletionStatus = JSON.parse(localStorage.getItem('pageCompletionStatus')) || [];
        localStorage.clear();
        localStorage.setItem('pageCompletionStatus', JSON.stringify(pageCompletionStatus));

        // Redirect to signin page
        window.location.href = "/signinpage";
    };

    calculateTotalDays = (registrationDate, submissionDate) => {
        const diffTime = Math.abs(submissionDate - registrationDate);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    calculateRemainingDays = (submissionDate) => {
        const currentDate = new Date();
        const diffTime = Math.abs(submissionDate - currentDate);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    formatDate = (date) => {
        if (!date) return '';
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    calculateCompletedPages = () => {
        // Fetch the page completion status from localStorage
        const savedCompletionStatus = JSON.parse(localStorage.getItem('pageCompletionStatus')) || [];
        return savedCompletionStatus.filter(page => page).length; // Count the number of completed pages
    };


    handleStartTyping = () => {
        this.setState({ navigateToTyping: true });
    };

    render() {
        const { admin, userData, remainingDays, registrationDate, submissionDate, totalDays, totalPages, completedPages, loading, error, navigateToTyping, selectedSet } = this.state;

        if (loading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        if (!userData) {
            return <div>No user data available</div>;
        }

        if (navigateToTyping) {
            return <Navigate to="/mypage" state={{ selectedSet }} />; // Navigate to MyPage with selectedSet
        }

        return (
            <div>
                {admin ? (
                    <Dashboard />
                ) : (
                    <div className='containerr'>
                        <div className="dashboard">
                            <h1>Welcome to Page Typing</h1>
                            
                            <table className="dashboard-table">
                                <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <td>{userData.fname} {userData.lname}</td>
                                    </tr>
                                    <tr>
                                        <th>Email Id</th>
                                        <td>{userData.email}</td>
                                    </tr>
                                    <tr>
                                        <th>Starting Date of Assignment</th>
                                        <td>{registrationDate}</td>
                                    </tr>
                                    <tr>
                                        <th>Last Date of Submission</th>
                                        <td>{submissionDate}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Number of Pages</th>
                                        <td>{totalPages}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Completed Pages</th>
                                        <td>{completedPages}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Number of Days</th>
                                        <td>{totalDays}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Number of Days Left</th>
                                        <td>{remainingDays}</td>
                                    </tr>
                                    <tr>
                                        <th>Selected Set</th>
                                        <td>{selectedSet}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p className="note">Note: You can submit your page only once you have completed the page.</p>
                            <button className="expired-button ms-3" onClick={this.handleStartTyping}>
                                Start Typing
                            </button>
                            <p className="result-info">Result will be generated after completion of 50 Pages</p>
                        </div>

                        <button className='btn btn-primary' onClick={this.logout}>Log Out</button>
                    </div>
                )}
            </div>
        );
    }
}

export default UserInformation;
