import React from 'react';
import { Button, Typography, Box } from '@mui/material';

const HeroSection = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${require('../assets/start_typing.jpg')})`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '500px',
        position: 'relative', // Position relative to use absolute positioning inside
        color: '#fff',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: '#000', // Set text color to black
          position: 'absolute',
          top: '20px', // Adjust the vertical positioning
          right: '120px', // Adjust the horizontal positioning
        }}
      >
        Type and Earn
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: '#000', // Set text color to black
          position: 'absolute',
          top: '60px', // Adjust this as needed
          right: '190px',
        }}
      >
        (Start your work today)
        <br></br>
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{
          position: 'absolute',
          top: '100px', // Adjust this as needed
          right: '200px',
          mt: 2,
        }}
      >
        Start Typing
      </Button>
    </Box>
  );
};

export default HeroSection;
