import React, { Component } from 'react';
import './Result.css';
import { Navigate, Link } from 'react-router-dom';


export class Result extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: null,
      admin: false,
      registrationDate: null,
      remainingDays: 0,
      submissionDate: null,
      totalDays: 0,
      completedPages: 0,
      
      loading: true,
      error: null,
      navigateToTyping: false,
      pagesData: [], // Adding state to store page accuracy and time data
    };
  }

  componentDidMount() {
    // Fetch user data and other relevant information
    fetch("http://localhost:5001/userData", {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        token: window.localStorage.getItem("token"),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          const { userType, registrationDate, submissionDate, typingSet } = data.data;

          if (userType === "Admin") {
            this.setState({ admin: true });
          }

          const regDate = new Date(registrationDate);
          const subDate = new Date(submissionDate);
          const totalDays = this.calculateTotalDays(regDate, subDate);
          const remainingDays = this.calculateRemainingDays(subDate);
  
          
          // Count completed pages from localStorage
          const completedPages = this.calculateCompletedPages();

          // Example of pages data (In real-world, you would fetch this data from your backend)
          const pagesData = this.generateSamplePageData();

          this.setState({
            userData: data.data,
            registrationDate: this.formatDate(regDate),
            submissionDate: this.formatDate(subDate),
            totalDays,
            remainingDays,
            completedPages,
            typingSet, // Store typingSet in state
            loading: false,
            pagesData, // Store pages data in state
          });
        }
      })
      .catch((error) => {
        this.setState({ error: "Failed to fetch user data", loading: false });
      });
  }

  calculateTotalDays = (registrationDate, submissionDate) => {
    const diffTime = Math.abs(submissionDate - registrationDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  calculateRemainingDays = (submissionDate) => {
    const currentDate = new Date();
    const diffTime = submissionDate - currentDate;
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  formatDate = (date) => {
    if (!date) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  calculateCompletedPages = () => {
    const savedCompletionStatus = JSON.parse(localStorage.getItem('pageCompletionStatus')) || [];
    return savedCompletionStatus.filter(page => page).length; // Count the number of completed pages
  };

  generateSamplePageData = (numberOfPages = 5) => {
    
    const sampleData = [];
  
    for (let i = 1; i <= numberOfPages; i++) {
      const accuracy = `${(Math.random() * (100 - 95) + 95).toFixed(2)}%`; // Random accuracy between 95% and 100%
      const time = `${Math.floor(Math.random() * (15 - 8) + 8)} minutes`; // Random time between 8 and 15 minutes
  
      sampleData.push({
        page: i,
        accuracy,
        time,
      });
    }
  
    // Return the generated sample data
    return sampleData;
  };
  

 

  handleBack = () => {
    window.location.href = '/userinformation';
  };

  render() {
    const {
      userData,
      registrationDate,
      submissionDate,
      completedPages,
      loading,
      error,
      pagesData,
    } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    if (!userData) {
      return <div>No user data available</div>;
    }

    return (
      <div>
        <div className="display">
          <button onClick={this.handleBack} className="button">
           
            Back
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
            </svg>
          </button>

          <h3 className="mt-5 fw-bold">Result</h3>
          <p><strong>Employee Name:</strong> {userData.fname} {userData.lname}</p>
          <p><strong>Pages Completed:</strong> {completedPages}</p>
          <p><strong>Work Assignment Date:</strong> {registrationDate}</p>
          <p><strong>Work Submission Date:</strong> {submissionDate}</p>
          <p><strong>Total Accuracy:</strong> {/* Add logic for total accuracy */}</p>
        </div>

        {/* Table for Page, Accuracy, Time, and Check Details */}
        <div className="table-responsive mt-4">
          <table className="table table-striped">
            
            <tbody>
              {pagesData.map((pageData, index) => (
                <tr key={index}>
                  <td>{pageData.page}</td>
                  <td>{pageData.accuracy}</td>
                  <td>{pageData.time}</td>
                  <td>
                  <Link to={`/CheckResult/`}>
                      <button type="button" className="btn btn-success">Check result</button>
                  </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Result;