import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const TypingPage = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [paragraphContent, setParagraphContent] = useState('');
  const [textFields, setTextFields] = useState(['', '', '', '', '', '']);
  const [combinedAccuracy, setCombinedAccuracy] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedSet: locationSet, pageIndex } = location.state || {};

  useEffect(() => {
    let set = locationSet;

    if (!set) {
      set = localStorage.getItem('selectedSet');
    } else {
      localStorage.setItem('selectedSet', set); // Save it to local storage
    }

    if (!set) {
      setParagraphContent('Please select a set.');
      return;
    }

    const fetchPages = async () => {
      try {
        const response = await axios.get(`http://localhost:5001/api/pages/${set}?start=1&end=20`);
        if (response.status !== 200) {
          throw new Error('Pages not found');
        }

        if (pageIndex) {
          setPageNumber(pageIndex);
        }
      } catch (err) {
        console.error('Error fetching pages:', err);
      }
    };

    fetchPages();
  }, [locationSet, pageIndex]);

  useEffect(() => {
    let set = locationSet || localStorage.getItem('selectedSet');

    if (!set) return;

    const fetchParagraph = async () => {
      try {
        if (isNaN(pageNumber)) {
          throw new Error('Invalid page number');
        }

        const response = await axios.get(`http://localhost:5001/api/paragraphs/${set}/${pageNumber}`);
        if (response.status !== 200) {
          throw new Error('Paragraph not found');
        }
        setParagraphContent(response.data.content);
      } catch (err) {
        console.error('Error fetching paragraph:', err);
        setParagraphContent('Paragraph not available.');
      }
    };

    fetchParagraph();

    const submissionStatus = localStorage.getItem(`Page${pageNumber}Submitted`);
    if (submissionStatus) {
      setIsSubmitted(true);
    }
  }, [pageNumber, locationSet]);

  const handleLogout = () => {
    window.localStorage.clear();
    window.location.href = '/signinpage';
  };

  const handleChange = (index, event) => {
    const newTextFields = [...textFields];
    newTextFields[index] = event.target.value;
    setTextFields(newTextFields);
    calculateCombinedAccuracy(newTextFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (textFields.some(field => field.trim() === '')) {
      alert('Please complete all typing fields before submitting.');
      return;
    }

    const email = prompt('Please enter your email to confirm submission:');
    if (email) {
      setIsSubmitted(true);
      localStorage.setItem(`Page${pageNumber}Submitted`, 'true');
      navigate('/SuccessPage', { state: { fromPage: `Page ${pageNumber}` } });
    } else {
      alert('Submission cancelled. Email is required.');
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    alert('Copy-paste is not allowed. Please type the text manually.');
  };

  const calculateCombinedAccuracy = (inputs) => {
    let totalCorrectCharacters = 0;
    let totalCharacters = 0;

    inputs.forEach((input, index) => {
      const referenceSentence = paragraphContent.split('®')[index] || ''; // Adjust to match your paragraph format
      totalCharacters += referenceSentence.length;
      for (let i = 0; i < input.length; i++) {
        if (input[i] === referenceSentence[i]) {
          totalCorrectCharacters++;
        }
      }
    });

    const accuracy = totalCharacters ? ((totalCorrectCharacters / totalCharacters) * 100).toFixed(2) : 0;
    setCombinedAccuracy(accuracy);
  };

  const handleCancel = () => {
    setTextFields(['', '', '', '', '', '']);
    setCombinedAccuracy(null);
  };

  const handleCheckResult = () => {
    navigate('/CheckResult', {
      state: {
        paragraph: paragraphContent,
        textFields,
        combinedAccuracy 
      }
    });
  };

  const handleBack = () => {
    navigate('/MyPage');
  };

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  return (
    <div>
      <div className="navbar1" >
        <span className="navbar-brand">Page Typing</span>
        <div className="navbar-user">
          <button onClick={handleLogout} className="navbar-button">LOGOUT</button>
          <span className="border-1 bg-success p-4 w-100 text-white me-4">User</span>
        </div>
      </div>
      <div>
        <button onClick={handleBack} className="Backbtn">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
          </svg>
          Back
        </button>
        <button onClick={toggleFullScreen} className="fullScreen">Fullscreen</button>
      </div>
      <hr style={{marginTop:'90px',width:'80%', marginLeft:'10%'}} />
      <div className="parabox">
        <p>{paragraphContent}</p>
      </div>
      <form onSubmit={handleSubmit}>
        {textFields.map((text, index) => (
          <div key={index} style={{ marginBottom: '0px', position: 'relative' }}>
            <label style={{ display: 'block', marginBottom: '5px', marginLeft:'10%' }}>Typing Field {index + 1}:</label>
            <textarea
              value={text}
              onChange={(event) => handleChange(index, event)}
              onPaste={handlePaste}
              style={{
                width: '80%',
                color: 'green',
                padding: '10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                height: '40px',
                marginLeft: '10%'
              }}
            />
          </div>
        ))}
        <button type="submit" style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Submit
        </button>
        <button type="button" onClick={handleCancel} style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer', marginLeft: '10px' }}>
          Cancel
        </button>
        <button type="button" onClick={handleCheckResult} style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#2196F3', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer', marginLeft: '10px' }}>
          Check Result
        </button>
      </form>
      <div>
        <p>Combined Accuracy: {combinedAccuracy}%</p>
      </div>
    </div>
  );
};

export default TypingPage;
