import React from 'react';
import { Container, Box, Typography, Grid, TextField, Button, Card, CardContent } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import CardMedia from '@mui/material/CardMedia';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import contactImage from '../assets/contact1.jpg'; 

// Import marker images
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';

// Set default Leaflet marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
});

const Contact = () => {
  return (
    
    <Container sx={{ marginTop: 0 }}>
      <Navbar />
      {/* Header Image */}
      <Box sx={{ position: 'relative', textAlign: 'center' }}>
        <CardMedia
          component="img"
          height="500"
          image={contactImage}
          alt="Contact Us"
          sx={{
            width: '100%',
            objectFit: 'cover',
            filter: 'brightness(0.8)',
          }}
        />
        <Typography
          variant="h4"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
 
        </Typography>
      </Box>

      {/* Contact Info */}
      <Grid container spacing={2} justifyContent="center" sx={{ my: 4 }}>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, backgroundColor: '#d1d9e6' }}>
            <LocationOnIcon fontSize="large" sx={{ mr: 2 }} />
            <Typography>Kolkata, West Bengal - 745008</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, backgroundColor: '#d1d9e6' }}>
            <EmailIcon fontSize="large" sx={{ mr: 4 }} />
            <Typography>flexdeskpagetyping@gmail.com</Typography>
          </Card>
        </Grid>
      </Grid>

      {/* Map and Contact Form */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <MapContainer center={[22.5726, 88.3639]} zoom={13} style={{ height: '100%', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={[22.5726, 88.3639]}>
              <Popup>
                We are here.
              </Popup>
            </Marker>
          </MapContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Contact Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="First Name" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Last Name" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Subject" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Your Message"
                    variant="outlined"
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" color="primary">
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
            
          </Card>
        </Grid>
      </Grid>
    </Container>
    
  );
        
 
};

export default Contact;
