import React from 'react';
import SignUp from '../components/SignUp';


const Contact = () => {
    // return <h2>Contact Page</h2>;
    return(
        <div>
          <SignUp></SignUp>
        </div>
    );

};

export default Contact;