import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const SuccessPage = () => {
  const [userName, setUserName] = useState('Rahul');

  // const handleLogout = () => {
  //   // Logic to handle logout
  //   console.log('User logged out');
  //   setUserName(''); // Clear the user name on logout
  // };


  return (
    <div className="mw-auto">
      {/* <div className="navbar">
        <span className="navbar-brand">Page Typing</span>
        {userName && (
          <div className="navbar-user">
            <span onClick={handleLogout} class="fs-6">LOGOUT</span>
            <span className="username">{userName}</span>
          </div>
        )}
      </div> */}

      <p className="mt-5 fs-5">Submitting....</p>
    </div>
  );
};

export default SuccessPage;