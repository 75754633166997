// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// // import './ParagraphPage.css';

// function ParagraphPage() {
//   const { pageIndex } = useParams();
//   const [paragraph, setParagraph] = useState('');
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     const fetchParagraph = async () => {
//       setLoading(true);
//       try {
//         const response = await axios.get(`http://localhost:5001/paragraph/${pageIndex}`);
//         setParagraph(response.data.content);
//       } catch (error) {
//         console.error('Error fetching paragraph:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchParagraph();
//   }, [pageIndex]);

//   return (
//     <div>
//       {loading ? (
//         <p>Loading...</p>
//       ) : (
//         <div className="paragraph-content">
//           <p>{paragraph}</p>
//         </div>
//       )}
//     </div>
//   );
// }

// export default ParagraphPage;




// import React, { useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import axios from 'axios';
// // import '../Components/PagesStyle.css';
// import './ParagraphsPages.css';

// const ParagraphsPage = () => {
//   const [userName, setUserName] = useState('Rahul');
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [isFullScreen, setIsFullScreen] = useState(false);
//   const [textFields, setTextFields] = useState(['', '', '', '', '', '']);
//   const [combinedAccuracy, setCombinedAccuracy] = useState(null);
//   const [paragraph, setParagraph] = useState('');
//   const navigate = useNavigate();
//   const { pageIndex } = useParams();

//   useEffect(() => {
//     const fetchParagraph = async () => {
//       try {
//         const response = await axios.get(`http://localhost:5001/paragraph/${pageIndex}`);
//         setParagraph(response.data.content);
//       } catch (error) {
//         console.error('Error fetching paragraph:', error);
//       }
//     };

//     fetchParagraph();

//     const submissionStatus = localStorage.getItem(`Page${pageIndex}Submitted`);
//     if (submissionStatus) {
//       setIsSubmitted(true);
//     }
//   }, [pageIndex]);

//   const handleLogout = () => {
//     window.localStorage.clear();
//     window.location.href = '/signinpage';
//   };

//   const handleChange = (index, event) => {
//     const newTextFields = [...textFields];
//     newTextFields[index] = event.target.value;
//     setTextFields(newTextFields);
//     calculateCombinedAccuracy(newTextFields);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (textFields.some(field => field.trim() === '')) {
//       alert('Please complete all typing fields before submitting.');
//       return;
//     }

//     const email = prompt('Please enter your email to confirm submission:');
//     if (email) {
//       setIsSubmitted(true);
//       localStorage.setItem(`Page${pageIndex}Submitted`, 'true');
//       navigate('/SuccessPage', { state: { fromPage: `Page ${pageIndex}` } });
//     } else {
//       alert('Submission cancelled. Email is required.');
//     }
//   };

//   const handlePaste = (event) => {
//     event.preventDefault();
//     alert('Copy-paste is not allowed. Please type the text manually.');
//   };

//   const calculateCombinedAccuracy = (inputs) => {
//     let totalCorrectCharacters = 0;
//     let totalCharacters = 0;

//     inputs.forEach((input, index) => {
//       const referenceSentence = paragraph.split('®')[index] || ''; // Adjust to match your paragraph format
//       totalCharacters += referenceSentence.length;
//       for (let i = 0; i < input.length; i++) {
//         if (input[i] === referenceSentence[i]) {
//           totalCorrectCharacters++;
//         }
//       }
//     });

//     const accuracy = totalCharacters ? ((totalCorrectCharacters / totalCharacters) * 100).toFixed(2) : 0;
//     setCombinedAccuracy(accuracy);
//   };

//   const handleCancel = () => {
//     setTextFields(['', '', '', '', '', '']);
//     setCombinedAccuracy(null);
//   };

//   const handleCheckResult = () => {
//     navigate('/CheckResult', {
//       state: {
//         paragraph,
//         textFields,
//         combinedAccuracy 
//       }
//     });
//   };

//   const handleBack = () => {
//     navigate('/MyPage');
//   };

//   const toggleFullScreen = () => {
//     if (!isFullScreen) {
//       if (document.documentElement.requestFullscreen) {
//         document.documentElement.requestFullscreen();
//       } else if (document.documentElement.mozRequestFullScreen) {
//         document.documentElement.mozRequestFullScreen();
//       } else if (document.documentElement.webkitRequestFullscreen) {
//         document.documentElement.webkitRequestFullscreen();
//       } else if (document.documentElement.msRequestFullscreen) {
//         document.documentElement.msRequestFullscreen();
//       }
//       setIsFullScreen(true);
//     } else {
//       if (document.exitFullscreen) {
//         document.exitFullscreen();
//       } else if (document.mozCancelFullScreen) {
//         document.mozCancelFullScreen();
//       } else if (document.webkitExitFullscreen) {
//         document.webkitExitFullscreen();
//       } else if (document.msExitFullscreen) {
//         document.msExitFullscreen();
//       }
//       setIsFullScreen(false);
//     }
//   };

//   if (isSubmitted) {
//     return (
//       <div>
//         <div className="navbar" id='parapagenav'>
//           <span className="navbar-brand">Page Typing</span>
//           {userName && (
//             <div className="navbar-user">
//               <button onClick={handleLogout} className="navbar-button">LOGOUT</button>
//               <span className="username">{userName}</span>
//             </div>
//           )}
//         </div>
//         <div>
//           <button onClick={handleBack} className="Backbtn">
//             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
//               <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
//             </svg>
//             Back
//           </button>
//           <hr className="w-75 mx-auto text-center"></hr>
//         </div>
//         <div style={{ width: '70%', marginLeft: '16%' }}>
//           <p>This page is no longer accessible after submission.</p>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div>
//       <div className="navbar">
//         <span className="navbar-brand">Page Typing</span>
//         {userName && (
//           <div className="navbar-user">
//             <button onClick={handleLogout} className="navbar-button">LOGOUT</button>
//             <span className="username">{userName}</span>
//           </div>
//         )}
//       </div>
//       <div>
//         <button onClick={handleBack} className="Backbtn">
//           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
//             <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
//           </svg>
//           Back
//         </button>
//         <hr className="w-75 mx-auto text-center"></hr>
//       </div>
//       <div>
//         <button onClick={toggleFullScreen} className="fullscreen-button">
//           Toggle Fullscreen
//         </button>
//       </div>
//       <div className="parabox">
//         <p>{paragraph}</p>
//       </div>
//       <form onSubmit={handleSubmit}>
//         {textFields.map((text, index) => (
//           <div key={index}>
//             <label htmlFor={`textField${index + 1}`}>Sentence {index + 1}</label>
//             <textarea
//               id={`textField${index + 1}`}
//               value={text}
//               onChange={(e) => handleChange(index, e)}
//               onPaste={handlePaste}
//             />
//           </div>
//         ))}
//         <button type="submit" className="submit-button">Submit</button>
//         <button type="button" onClick={handleCancel} className="cancel-button">Cancel</button>
//         <button type="button" onClick={handleCheckResult} className="check-result-button">Check Result</button>
//       </form>
//       <div>
//         <p>Combined Accuracy: {combinedAccuracy}%</p>
//       </div>
//     </div>
//   );
// };

// export default ParagraphsPage;



import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
// import '../Components/PagesStyle.css';
import './ParagraphsPages.css';


const ParagraphsPage = () => {
  const [userName, setUserName] = useState('Rahul');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [textFields, setTextFields] = useState(['', '', '', '', '', '']);
  const [combinedAccuracy, setCombinedAccuracy] = useState(null);
  const [paragraph, setParagraph] = useState('');
  const navigate = useNavigate();
  const { pageIndex } = useParams();

  useEffect(() => {
    const fetchParagraph = async () => {
      try {
        const response = await axios.get(`http://localhost:5001/paragraph/${pageIndex}`);
        setParagraph(response.data.content);
      } catch (error) {
        console.error('Error fetching paragraph:', error);
      }
    };

    fetchParagraph();

    const submissionStatus = localStorage.getItem(`Page${pageIndex}Submitted`);
    if (submissionStatus) {
      setIsSubmitted(true);
    }
  }, [pageIndex]);

  const handleLogout = () => {
    window.localStorage.clear();
    window.location.href = '/signinpage';
  };

  const handleChange = (index, event) => {
    const newTextFields = [...textFields];
    newTextFields[index] = event.target.value;
    setTextFields(newTextFields);
    calculateCombinedAccuracy(newTextFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (textFields.some(field => field.trim() === '')) {
      alert('Please complete all typing fields before submitting.');
      return;
    }

    const email = prompt('Please enter your email to confirm submission:');
    if (email) {
      setIsSubmitted(true);
      localStorage.setItem(`Page${pageIndex}Submitted`, 'true');
      navigate('/SuccessPage', { state: { fromPage: `Page ${pageIndex}` } });
    } else {
      alert('Submission cancelled. Email is required.');
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    alert('Copy-paste is not allowed. Please type the text manually.');
  };

  const calculateCombinedAccuracy = (inputs) => {
    let totalCorrectCharacters = 0;
    let totalCharacters = 0;

    inputs.forEach((input, index) => {
      const referenceSentence = paragraph.split('®')[index] || ''; // Adjust to match your paragraph format
      totalCharacters += referenceSentence.length;
      for (let i = 0; i < input.length; i++) {
        if (input[i] === referenceSentence[i]) {
          totalCorrectCharacters++;
        }
      }
    });

    const accuracy = totalCharacters ? ((totalCorrectCharacters / totalCharacters) * 100).toFixed(2) : 0;
    setCombinedAccuracy(accuracy);
  };

  const handleCancel = () => {
    setTextFields(['', '', '', '', '', '']);
    setCombinedAccuracy(null);
  };

  const handleCheckResult = () => {
    navigate('/CheckResult', {
      state: {
        paragraph,
        textFields,
        combinedAccuracy 
      }
    });
  };

  const handleBack = () => {
    navigate('/MyPage');
  };

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  // if (isSubmitted) {
  //   return (
  //     <div>
  //       <div className="navbar">
  //         <span className="navbar-brand">Page Typing</span>
  //         {userName && (
  //           <div className="navbar-user">
  //             <button onClick={handleLogout} className="navbar-button">LOGOUT</button>
  //             <span className="username">{userName}</span>
  //           </div>
  //         )}
  //       </div>
  //       <div>
  //         <button onClick={handleBack} className="Backbtn">
  //           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
  //             <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
  //           </svg>
  //           Back
  //         </button>
  //         <hr className="w-75 mx-auto text-center"></hr>
  //       </div>
  //       <div style={{ width: '70%', marginLeft: '16%' }}>
  //         <p>This page is no longer accessible after submission.</p>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div>
      <div className="navbar1" >
        <span className="navbar-brand">Page Typing</span>
        {userName && (
          <div className="navbar-user">
            <button onClick={handleLogout} className="navbar-button">LOGOUT</button>
            <span className="border-1 bg-success p-4 w-100 text-white me-4">{userName}</span>
          </div>
        )}
      </div>
      <div>
        <button onClick={handleBack} className="Backbtn">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
          </svg>
          Back
        </button>
        <button onClick={toggleFullScreen} className="fullScreen">
  Fullscreen
</button>
        
      </div><hr style={{marginTop:'90px',width:'80%', marginLeft:'10%'}}></hr>
      <div>
      
      </div>
      <div className="parabox" >
        <p>{paragraph}</p>
      </div>
      <form onSubmit={handleSubmit} >
        {textFields.map((text, index) => (
         <div key={index} style={{ marginBottom: '0px', position: 'relative' }}>
         <label style={{ display: 'block', marginBottom: '5px',marginLeft:'10%' }}>Typing Field {index + 1} :</label>
         <textarea
           value={text}
           onChange={(event) => handleChange(index, event)}
           onPaste={handlePaste}
           style={{
             width: '80%',
             color:'green',
             padding: '10px',
             fontSize: '16px',
             border: '1px solid #ccc',
             borderRadius: '4px',
             height: '40px',
            marginLeft:'10%'
           }}
         />
       </div>
        ))}
         
      </form>
      <button onClick={handleSubmit} style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Submit
        </button>
        <button onClick={handleCancel} style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer', marginLeft: '10px' }}>
          Cancel
        </button>
        <button onClick={handleCheckResult} style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#2196F3', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer', marginLeft: '10px' }}>
          Check Result
        </button> 
      <div>
        <p>Combined Accuracy: {combinedAccuracy}%</p>
      </div>
    </div>
  );
};

export default ParagraphsPage;