import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "../components/Info.css";

const Info = () => {
  const [user, setUser] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetch(`http://localhost:5001/getUser/${id}`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data); // Debugging line
        setUser(data);
      });
  }, [id]);

  // Define the formatDate function here
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="info-container">
      <h2>User Details</h2>
      <table>
        <tbody>
          <tr>
            <th>Email</th>
            <td>{user.email}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{user.fname} {user.lname}</td>
          </tr>
          <tr>
            <th>Pages Completed</th>
            <td>{user.pagesCompleted || 'N/A'}</td>
          </tr>
          <tr>
            <th>Set</th>
            <td>{user.set || 'N/A'}</td>
          </tr>
          <tr>
            <th>Registration Date</th>
            <td>{formatDate(user.registrationDate)}</td>
          </tr>
          <tr>
            <th>Submission Date</th>
            <td>{formatDate(user.submissionDate)}</td>
          </tr>
          <tr>
            <th>Total Days</th>
            <td>{user.totalDays || 'N/A'}</td>
          </tr>
          <tr>
            <th>Days Left</th>
            <td>{user.remainingDays || 'N/A'}</td>
          </tr>
          <tr>
            <th>Account Password</th>
            <td>{user.password}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Info;