import React from 'react';
import { Box, Typography } from '@mui/material';
import img1 from '../assets/about.jpg';
import backgroundImg from '../assets/about_backgrond.jpeg';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const About = () => {
  return (
    <>
      <Navbar />
      <Box>
        {/* Horizontal Image at the Top */}
        <Box
          component="img"
          src={img1}
          alt="FlexDesk"
          sx={{
            width: '100%',
            height: '500px',
            marginBottom: '-5px',
          }}
        />

        {/* Background Image with Text Content */}
        <Box
          sx={{
            padding: '20px',
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: '#fff',
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
            About Us
          </Typography>
          <Typography paragraph>
            Welcome to FlexDesk, your go-to destination for versatile and dynamic
            workspace solutions. At FlexDesk, we believe that the right environment
            can significantly enhance productivity and creativity. Our mission is to
            provide innovative desk setups that cater to the diverse needs of
            modern professionals, students, and creatives.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
            Our Story
          </Typography>
          <Typography paragraph>
            Flex Desk was born out of the realization that the traditional, one-size-fits-all
            workspace no longer meets the demands of today's fast-paced and
            ever-evolving work culture. With the rise of remote work, freelancing, and
            flexible hours, we saw a need for workspaces that could easily adapt to
            different tasks and preferences. Our team of designers and engineers set
            out to create desks that combine functionality, style, and flexibility,
            allowing users to transform their workspaces with ease.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
            Our Products
          </Typography>
          <Typography paragraph>
            At FlexDesk, we offer a range of customizable desks designed to fit any
            space and lifestyle. Whether you're looking for a compact setup for your
            home office, a standing desk for ergonomic benefits, or a multi-functional
            workstation for your creative projects, we have you covered. Our products
            are built with high-quality materials and innovative features to ensure
            durability, comfort, and efficiency.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
            Join the FlexDesk Community
          </Typography>
          <Typography paragraph>
            We invite you to explore our range of products and discover how FlexDesk
            can transform your workspace. Follow us on social media and subscribe to
            our newsletter for the latest updates, tips, and special offers. Together,
            let's create workspaces that inspire and empower.
          </Typography>
          <Typography>
            Thank you for choosing FlexDesk!
          </Typography>
        </Box>
      </Box>
      <Footer/>
    </>
  );
};

export default About;
