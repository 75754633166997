import React from 'react'
import SignIn from '../components/SignIn';


function SignInpage() {
  return (
    <div>
       <SignIn/>
    </div>
  )
}


export default SignInpage;