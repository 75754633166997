import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

const WhyChooseUsSection = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" sx={{ color: '#22336a', fontWeight: 'bold' }} gutterBottom>
        Why to choose us..!
     </Typography>
     <Grid container spacing={3} alignItems="center">
  <Grid item xs={12} md={6}>
    <Typography variant="body1">
      Work Anywhere, Anytime: Datamatic cater to the modern professional's need for flexibility. Whether you're an employee who prefers working in different parts of the office or a freelancer who enjoys a change of scenery, flex desks provide the freedom to choose your workspace. Work Anywhere, Anytime: Flex desks cater to the modern professional's need for flexibility. Whether you're an employee who prefers working in different parts of the office or a freelancer who enjoys a change of scenery, flex desks provide the freedom to choose your workspace.
    </Typography>
  </Grid>
  <Grid item xs={12} md={6}>
    <img src={require('../assets/whytochooseus.jpeg')} alt="Why Choose Us" style={{ width: '100%', height: '450px', aspectRatio: '1 / 1' }} />
  </Grid>
</Grid>

    </Box>
  );
};

export default WhyChooseUsSection;
