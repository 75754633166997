import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';

const testimonials = [
  {
    name: 'Romesh Rao',
    date: 'May 23, 2024',
    image: '/path/to/romesh.jpg',
    rating: 5,
    testimonial: 'FlexDesk has completely transformed the way we work. The flexibility to choose where to sit each day has not only boosted our productivity but also our creativity. The collaborative environment has made team projects more dynamic and enjoyable.',
  },
  {
    name: 'Manish Rao',
    date: 'May 23, 2024',
    image: '/path/to/manish.jpg',
    rating: 4,
    testimonial: 'As a startup, we needed a workspace solution that could grow with us. FlexDesk was the perfect fit. The cost savings are significant, and the ability to scale our space up or down based on our needs is invaluable.',
  },
  {
    name: 'Amita Rao',
    date: 'May 23, 2024',
    image: '/path/to/amita.jpg',
    rating: 4,
    testimonial: 'FlexDesk has redefined what it means to work in an office. The ability to work in different areas depending on my tasks for the day has greatly improved my work-life balance. I can’t imagine going back to a traditional desk setup.',
  },
];

const Testimonials = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        What Our Clients Say
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        Our clients are at the heart of everything we do at FlexDesk.<br></br> Their feedback drives our commitment to providing the best flexible workspace solutions. <br></br>Here's what some of our satisfied clients have to say:
      </Typography>
      <Grid container spacing={3} mt={3}>
        {testimonials.map((testimonial) => (
          <Grid item key={testimonial.name} xs={4} md={4}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar src={testimonial.image} />
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      {testimonial.name}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {testimonial.date}
                    </Typography>
                  </Box>
                  <Box sx={{ ml: 'auto' }}>
                    <Rating value={testimonial.rating} readOnly />
                  </Box>
                </Box>
                <Typography variant="body1" mt={6}>
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonials;