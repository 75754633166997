import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const services = [
  { title: "Content Typing & Formatting", description: "Provide a description here.", img: require('../assets/img_1.png') },
  { title: "SEO-Optimized Typing", description: "Provide a description here.", img: require('../assets/img_2.png') },
  { title: "Data Entry & Form Filling Jobs", description: "Provide a description here.", img: require('../assets/img_3.png') },
  { title: "Multilingual Typing", description: "Provide a description here.", img: require('../assets/img_4.png') },
  { title: "Content Migration", description: "Provide a description here.", img: require('../assets/img_5.png') },
];

const ServicesSection = () => {
  return (
    <Grid container spacing={2.4} sx={{ p: 10, backgroundColor: '#22336a', color: '#fff' }}>
      {services.map((service, index) => (
        <Grid item xs={2} sm={4} md={2.4} key={index}>
          <Paper sx={{ p: 2, height: '100%', textAlign: 'center' }}>
            <img
              src={service.img}
              alt={service.title}
              style={{
                width: '70px',
                height: '70px',
                borderRadius: '20%',
                objectFit: 'cover',
                marginBottom: '1rem'
              }}
            />
            <Typography variant="h6">{service.title}</Typography>
            <Typography variant="body2">{service.description}</Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
 
  );
};

export default ServicesSection;
