import React from 'react';
import { Grid, Typography, Box, Divider, Link } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Footer = () => {
  return (
    <Box sx={{ p: 3, backgroundColor: '#22336a', color: '#fff' }}>
      <Grid container spacing={3}>
        <Grid item xs={5} sm={4}>
          <Typography variant="h6">DATAMATIC</Typography>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ borderColor: '#fff', borderWidth: 1 }} />
        <Grid item xs={5} sm={4}>
          <Typography variant="h6">Quick Links</Typography>
          <Link href="/" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Home</Link>
          <Link href="/about" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>About</Link>
          <Link href="/dashboard" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Dashboard</Link>
          <Link href="/services" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Services</Link>
          <Link href="/contact" color="inherit" underline="none">Contact</Link>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ borderColor: '#fff', borderWidth: 1 }} />
        <Grid item xs={5} sm={2}>
          <Typography variant="h6">Contact Us</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
            <LocationOnIcon sx={{ mr: 1 }} />
            <Typography variant="body2">Kolkata, West Bengal - 745008</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
            <MailOutlineIcon sx={{ mr: 1 }} />
            <Typography variant="body2">flexdeskpagetyping@gmail.com</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FacebookIcon sx={{ color: '#fff' }} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <InstagramIcon sx={{ color: '#fff' }} />
            </a>
            <a href="https://wa.me/" target="_blank" rel="noopener noreferrer">
              <WhatsAppIcon sx={{ color: '#fff' }} />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;