import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button, Form } from 'react-bootstrap';
import "../components/Dashboard.css";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Navigate, Link } from 'react-router-dom';

export class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      showModal: false,
      selectedUser: null,
      updatedUser: {
        submissionDate: '', // Initialize with submissionDate
      },
      totalUsers: 0,
    };
  }

  componentDidMount() {
    this.getAllUsers();
  }

  getAllUsers = () => {
    fetch("http://localhost:5001/getAllUser", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "userData");
        const formattedData = data.data.map(user => {
          const registrationDate = new Date(user.registrationDate);
          const completedPages = this.calculateCompletedPages();
          const submissionDate = new Date(user.submissionDate);
          const totalDays = this.calculateTotalDays(registrationDate, submissionDate);
          const remainingDays = this.calculateRemainingDays(submissionDate);
          return {
            ...user,
            registrationDate: this.formatDate(registrationDate),
            submissionDate: this.formatDate(submissionDate),
            totalDays,
            completedPages,
            remainingDays
          };
        });
        this.setState({ data: formattedData, totalUsers: formattedData.length, 
        });
      });
  };

  logout = () => {
    window.localStorage.clear();
    window.location.href = "/signinpage";
  };

  deleteUser = (id, name) => {
    if (window.confirm(`Are you sure you want to delete ${name}?`)) {
      fetch("http://localhost:5001/deleteUser", {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          userid: id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data);
          this.getAllUsers(); // Fetch all users again to update the state
        });
    }
  };
  calculateCompletedPages = () => {
    // Fetch the page completion status from localStorage
    const savedCompletionStatus = JSON.parse(localStorage.getItem('pageCompletionStatus')) || [];
    return savedCompletionStatus.filter(page => page).length; // Count the number of completed pages
};

  calculateTotalDays = (registrationDate, submissionDate) => {
    const diffTime = submissionDate - registrationDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  calculateRemainingDays = (submissionDate) => {
    const currentDate = new Date();
    const diffTime = submissionDate - currentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 ? diffDays : 0;
  };

  formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2);
    return `${day}-${month}-${year}`;
  };

  calculateCompletedPages = () => {
    const savedCompletionStatus = JSON.parse(localStorage.getItem('pageCompletionStatus')) || [];
    return savedCompletionStatus.filter(page => page).length; // Count the number of completed pages
  };

  handleShowModal = (user) => {
    this.setState({
      showModal: true,
      selectedUser: user,
      updatedUser: { submissionDate: user.submissionDate }, // Only set submissionDate
    });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, selectedUser: null, updatedUser: {} });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      updatedUser: { ...prevState.updatedUser, [name]: value }
    }));
  };

  handleUpdateUser = () => {
    const { selectedUser, updatedUser } = this.state;
    fetch("http://localhost:5001/updateUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        userid: selectedUser._id,
        submissionDate: updatedUser.submissionDate, // Update only submissionDate
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        alert(data.message);
        this.getAllUsers(); // Refresh the list of users
        this.handleCloseModal(); // Close the modal
      });
  };

  render() {
    const { data, showModal, updatedUser, totalUsers,completedPages,
    } = this.state;
    return (
      <div className='dashmain container'>
        <h1>Registered Employees</h1>
        
        <div className='inout'>
          <span><a href='./login' className='registration'>Registration</a></span>
          <a className='logout' onClick={this.logout}>Logout</a>
          <p>Total Users: {totalUsers}</p>
        </div>

        {data.map(i => {
          return (
            <div className='AllData' key={i._id}>
              <p>{i.email}</p>
              <p>Name: {i.fname} {i.lname}</p>
              <p>Pages Completed:{i.completedPages}</p>
              <p>Set:</p>
              <p>Starting Date: {i.registrationDate}</p>
              <p>Last Date: {i.submissionDate}</p>
              <p>Total Days: {i.totalDays}</p>
              <p>Days Left: {i.remainingDays}</p>
              <p>Account Password: {i.password}</p>
              <hr />
              <div className='Allbuttons'>
              <Link to={`/Result/`}>
                  <button type="button" className="btn btn-success">Check result</button>
                </Link>
                {/* <button type="button" className="btn btn-success">Check Result</button> */}
                <Link to={`/info/${i._id}`}>
                  <button type="button" className="btn btn-success">Dashboard</button>
                </Link>
                {/* <button type="button" className="btn btn-success">Dashboard</button> */}
                <button type="button" className="btn btn-success" onClick={() => this.handleShowModal(i)}>Update Form</button>
                
                <button type="button" className="btn btn-danger" onClick={() => this.deleteUser(i._id, i.fname)}>
                  Delete User <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          );
        })}

        <Modal show={showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update User Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formSubmissionDate">
                <Form.Label>Submission Date</Form.Label>
                <Form.Control
                  type="date"
                  name="submissionDate"
                  value={updatedUser.submissionDate}
                  onChange={this.handleInputChange} 
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleUpdateUser}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Dashboard;
